<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-cashier-period-bar @periodLoaded="onPeriodLoaded" @periodUnloaded="onPeriodUnloaded" ref="cashierBar"></v-cashier-period-bar>
      </v-col>
      <v-col cols="12" v-if="currentPeriod">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="transactions">รายการรอเรียกเก็บ</v-tab>
            <v-tab key="unpaidinvoices">รายการใบแจ้งหนี้ค้างชำระ</v-tab>
            <v-tab key="todayInvoices">รายการใบแจ้งหนี้วันนี้</v-tab>
            <v-tab key="historyInvoices" v-permission="'cashier-invoice-history'">รายการใบแจ้งหนี้เก่า</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="transactions">
              <v-model-table model-name="patientsTransactions" model-title="รายการรอเรียกเก็บ"
                :model-api="['models','Patient','Patients']"
                model-key="hn"
                :model-api-search="transactionQuery"
                :api-with="['UninvoicedTransactions','ActiveEncounters']"
                :headers="headersTransactions"
                :insertable="false"
                expandable
                searchable
              >
                <template v-slot:toolBarItem>
                  <v-radio-group v-model="currentEncounterType" row single-line hide-details>
                    <v-radio
                      label="ผู้ป่วยนอก"
                      value="AMB"
                    ></v-radio>
                    <v-radio
                      label="ผู้ป่วยใน"
                      value="IMP"
                    ></v-radio>
                  </v-radio-group>
                </template>
                <template v-slot:item.hn="{item}">
                  <v-icon color="red lighten-1" v-if="item.active_encounters && item.active_encounters.length>0">mdi-account-alert</v-icon>
                  <v-icon color="green lighten-1" v-else>mdi-account-check</v-icon>
                  {{ item.hn }}
                </template>
                <template v-slot:item.name_th="{item}">
                  <v-label-name :name-data="item.name_th"></v-label-name>
                </template>
                <template v-slot:item.name_en="{item}">
                  <v-label-name :name-data="item.name_en"></v-label-name>
                </template>
                <template v-slot:item.amount="{item}">
                  <label class="font-weight-medium green--text text--darken-3">{{ sumAmount(item.uninvoiced_transactions,'finalPrice').toFixed(2) }}</label>
                </template>
                <template v-slot:item.amountChargetoPatient="{item}">
                  <label class="font-weight-medium red--text text-darken-1">{{ sumAmountToCharge(item.uninvoiced_transactions).toFixed(2) }}</label>
                </template>
                <template v-slot:item.cashierAction="{item,actions}">
                  <v-btn class="mr-1" @click="makeStatement(item.hn,item.uninvoiced_transactions)">ออกใบแจ้งยอด</v-btn>&nbsp;
                  <v-btn class="mr-1" @click="makeInvoice(item.hn,item.uninvoiced_transactions,actions.reload)">ออกใบแจ้งหนี้</v-btn>&nbsp;
                </template>
                <template v-slot:expanded-item="{ headers,item,actions }">
                  <td :colspan="headers.length" class="pa-2">
                    <v-enhance-table
                      :items="item.uninvoiced_transactions"
                      :headers="transactionHeader"
                      show-select
                    >
                      <template v-slot:item.productCode="props">
                        {{ props.item.productCode }} {{ props.item.product.productName }}
                      </template>
                      <template v-slot:item.insurance="props">
                        <template v-if="props.item.sold_patients_insurances">
                          <v-label-master group-key="payerType" :item-code="props.item.sold_patients_insurances.payerType"></v-label-master>
                          <label v-if="props.item.sold_payer">({{props.item.sold_payer.payerName}})</label>
                        </template>
                        <template v-else>เงินสด</template>
                      </template>
                      <template v-slot:item.discount="props">
                        {{ (props.item.discount==0 || props.item.discount==null) ? "-" : props.item.discount+"%" }}
                      </template>
                      <template v-slot:item.price="props">
                        {{ props.item.price.toFixed(2) }} <v-icon v-if="props.item.price==0" color="orange darken-3">mdi-alert-circle</v-icon>
                      </template>
                      <template v-slot:item.totalPrice="props">
                        {{ props.item.totalPrice.toFixed(2) }} <v-icon v-if="props.item.totalPrice==0" color="orange darken-3">mdi-alert-circle</v-icon>
                      </template>
                      <template v-slot:item.finalPrice="props">
                        {{ props.item.finalPrice.toFixed(2) }} <v-icon v-if="props.item.finalPrice==0" color="orange darken-3">mdi-alert-circle</v-icon>
                      </template>
                      <template v-slot:body.append="props">
                        <tr>
                          <td><v-icon>mdi-subdirectory-arrow-right</v-icon></td>
                          <td :colspan="props.headers.length">
                            <v-btn class="mr-1" @click="makeStatement(item.hn,props.selectedItems)">ออกใบแจ้งยอด</v-btn>&nbsp;
                            <v-btn class="mr-1" @click="makeInvoice(item.hn,props.selectedItems,actions.reload)">ออกใบแจ้งหนี้</v-btn>&nbsp;
                            <label class="font-weight-medium green--text text--darken-3">ยอดรวม {{ sumAmount(props.selectedItems,'finalPrice').toFixed(2) }} บาท</label>&nbsp;
                            <label class="font-weight-medium red--text text-darken-1">เรียกเก็บผู้ป่วย {{ sumAmountToCharge(props.selectedItems).toFixed(2) }} บาท</label>
                          </td>
                        </tr>
                      </template>
                    </v-enhance-table>
                  </td>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item key="unpaidinvoices">
              <v-model-table model-name="patientsInvoices" model-title="รายการใบแจ้งหนี้ค้างชำระ"
                :model-api="['models','Patient','Patients']"
                model-key="hn"
                :model-api-search="invoiceQuery"
                api-with="UnpaidInvoices"
                :headers="headersInvoices"
                :insertable="false"
                expandable
                searchable
                ref="unpaidInvoiceTable"
              >
                <template v-slot:item.name_th="{item}">
                  <v-label-name :name-data="item.name_th"></v-label-name>
                </template>
                <template v-slot:item.name_en="{item}">
                  <v-label-name :name-data="item.name_en"></v-label-name>
                </template>
                <template v-slot:item.amountOutstanding="{item}">
                  <label class="font-weight-medium red--text text--darken-1">{{ sumAmount(item.unpaid_invoices,'amountOutstanding').toFixed(2) }}</label>
                </template>
                <template v-slot:expanded-item="{ headers,item }">
                  <td :colspan="headers.length" class="pa-0">
                    <v-data-table
                      :items="item.unpaid_invoices"
                      :headers="invoiceHeader"
                    >
                      <template v-slot:item.created_at="props">
                        <v-label-datetime :date-time="props.item.created_at" short-date-time></v-label-datetime>
                      </template>
                      <template v-slot:item.amountDue="props">
                        {{ props.item.amountDue.toFixed(2) }}
                      </template>
                      <template v-slot:item.amountPaid="props">
                        <label class="body-2 font-weight-bold green--text text--darken-3">{{ props.item.amountPaid.toFixed(2) }}</label>
                      </template>
                      <template v-slot:item.amountOutstanding="props">
                        <label class="font-weight-medium red--text text-darken-1">{{ props.item.amountOutstanding.toFixed(2) }}</label>
                      </template>
                      <template v-slot:item.invoiceAction="props">
                        <v-btn class="mr-1" v-permission="'cashier-invoice-void'" :disabled="(props.item.payments && props.item.payments.filter(o => o.isVoid == false).length>0) || !!props.item.isVoid" @click="makeVoidInvoice(props.item)">ยกเลิก</v-btn>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn class="mr-1"  v-on="on" :disabled="props.item.amountOutstanding==0 || !!props.item.isVoid">ชำระเงิน</v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="makePayment(props.item,'cash')">
                              <v-list-item-title>เงินสด</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="makePayment(props.item,'creditcard')">
                              <v-list-item-title>บัตรเครดิต</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="makePayment(props.item,'transfer')">
                              <v-list-item-title>โอนเงิน</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </td>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item key="todayInvoices">
              <v-model-table model-name="todayInvoices" model-title="รายการใบแจ้งหนี้วันนี้"
                :model-api="['models','Accounting','AccountingInvoices']"
                model-key="invoiceId"
                :api-with="['patient','payments','insurance']"
                api-scope="recent"
                api-order-by="created_at,desc"
                :headers="headersTodayInvoices"
                :insertable="false"
                expandable searchable
                serverPagination
                ref="todayInvoiceTable"
              >
                <template v-slot:item.isVoid="props">
                  <v-icon color="red" v-if="!!props.item.isVoid">mdi-file-remove-outline</v-icon>
                  <v-icon v-else>mdi-text-box-check-outline</v-icon>
                </template>
                <template v-slot:item.created_at="props">
                  <v-label-datetime :date-time="props.item.created_at" short-date-time></v-label-datetime>
                </template>
                <template v-slot:item.patient.name_th="props">
                  <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
                </template>
                <template v-slot:item.insurance="props">
                  <template v-if="props.item.insurance">
                    <v-label-master group-key="payerType" :item-code="props.item.insurance.payerType"></v-label-master>
                    <label v-if="props.item.insurance.payer">({{props.item.insurance.payer.payerName}})</label>
                  </template>
                  <template v-else>เงินสด</template>
                </template>
                <template v-slot:item.amount="props">
                  {{ props.item.amount.toFixed(2) }}
                </template>
                <template v-slot:item.amountOutstanding="props">
                  <label class="font-weight-medium red--text text-darken-1">{{ props.item.amountOutstanding.toFixed(2) }}</label>
                </template>
                <template v-slot:item.invoiceAction="props">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mr-1" v-on="on" :disabled="props.item.amountOutstanding==0 || !!props.item.isVoid">ชำระเงิน</v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="makePayment(props.item,'cash')">
                        <v-list-item-title>เงินสด</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="makePayment(props.item,'creditcard')">
                        <v-list-item-title>บัตรเครดิต</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="makePayment(props.item,'transfer')">
                        <v-list-item-title>โอนเงิน</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn class="mr-1" v-permission="'cashier-invoice-rebuild'" @click="rebuildInvoice(props.item.invoiceId)">Rebuild</v-btn>
                  <v-btn class="mr-1" v-permission="'cashier-invoice-void'" :disabled="(props.item.payments && props.item.payments.filter(o => o.isVoid == false).length>0) || !!props.item.isVoid" @click="makeVoidInvoice(props.item)">ยกเลิก</v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mr-1" v-on="on">พิมพ์</v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_summary_insurance')">
                        <v-list-item-title>แบบสรุป (ประกัน)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_summary_cgd')">
                        <v-list-item-title>แบบสรุป (กรมบัญชีกลาง)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_detail_insurance')">
                        <v-list-item-title>แบบรายละเอียด (ประกัน)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_detail_cgd')">
                        <v-list-item-title>แบบรายละเอียด (กรมบัญชีกลาง)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_detail_cgd_excess')">
                        <v-list-item-title>แบบแสดงส่วนเกิน (กรมบัญชีกลาง)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_en')">
                        <v-list-item-title>English (Summary)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_en_detail')">
                        <v-list-item-title>English (Detail)</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:expanded-item="{ headers,item }">
                  <td :colspan="headers.length" class="pa-0">
                    <v-enhance-table
                      :items="item.payments"
                      :headers="paymentHeader"
                    >
                      <template v-slot:item.isVoid="props">
                        <v-icon color="red" v-if="!!props.item.isVoid">mdi-file-remove-outline</v-icon>
                        <v-icon v-else>mdi-text-box-check-outline</v-icon>
                      </template>
                      <template v-slot:item.paymentAction="props">
                        <v-btn class="mr-1" v-permission="'cashier-payment-void'" :disabled="!!props.item.isVoid" @click="makeVoidPayment(props.item)">ยกเลิก</v-btn>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn class="mr-1"  v-on="on">พิมพ์</v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_summary_insurance')">
                              <v-list-item-title>แบบสรุป (ประกัน)</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_summary_cgd')">
                              <v-list-item-title>แบบสรุป (กรมบัญชีกลาง)</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_detail_insurance')">
                              <v-list-item-title>แบบรายละเอียด (ประกัน)</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_detail_cgd')">
                              <v-list-item-title>แบบรายละเอียด (กรมบัญชีกลาง)</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_detail_cgd_excess')">
                              <v-list-item-title>แบบแสดงส่วนเกิน (กรมบัญชีกลาง)</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_en')">
                              <v-list-item-title>English (Summary)</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_en_detail')">
                              <v-list-item-title>English (Detail)</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-enhance-table>
                  </td>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item key="historyInvoices" v-permission="'cashier-invoice-history'">
              <v-model-table model-name="historyInvoices" model-title="รายการใบแจ้งหนี้เก่า"
                :model-api="['models','Accounting','AccountingInvoices']"
                :model-api-search="historyInvoiceQuery"
                model-key="invoiceId"
                :api-with="['patient','payments','insurance']" api-order-by="created_at,desc"
                :headers="headersTodayInvoices"
                :insertable="false"
                expandable searchable
                serverPagination
                ref="historyInvoiceTable"
              >
                <template v-slot:toolBarItem="props">
                  <v-patient-select class="mr-1" v-model="currentHistoryHn" clearable></v-patient-select>
                  <v-date-field class="mr-1" v-model="currentHistoryFromDate" label="ตั้งแต่วันที่" hide-details clearable></v-date-field>
                  <v-date-field class="mr-1" v-model="currentHistoryToDate" label="ถึงวันที่" hide-details clearable></v-date-field>
                </template>
                <template v-slot:item.isVoid="props">
                  <v-icon color="red" v-if="!!props.item.isVoid">mdi-file-remove-outline</v-icon>
                  <v-icon v-else>mdi-text-box-check-outline</v-icon>
                </template>
                <template v-slot:item.created_at="props">
                  <v-label-datetime :date-time="props.item.created_at" short-date-time></v-label-datetime>
                </template>
                <template v-slot:item.patient.name_th="props">
                  <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
                </template>
                <template v-slot:item.insurance="props">
                  <template v-if="props.item.insurance">
                    <v-label-master group-key="payerType" :item-code="props.item.insurance.payerType"></v-label-master>
                    <label v-if="props.item.insurance.payer">({{props.item.insurance.payer.payerName}})</label>
                  </template>
                  <template v-else>เงินสด</template>
                </template>
                <template v-slot:item.amount="props">
                  {{ props.item.amount.toFixed(2) }}
                </template>
                <template v-slot:item.amountOutstanding="props">
                  <label class="font-weight-medium red--text text-darken-1">{{ props.item.amountOutstanding.toFixed(2) }}</label>
                </template>
                <template v-slot:item.invoiceAction="props">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mr-1" v-on="on" :disabled="props.item.amountOutstanding==0 || !!props.item.isVoid">ชำระเงิน</v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="makePayment(props.item,'cash')">
                        <v-list-item-title>เงินสด</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="makePayment(props.item,'creditcard')">
                        <v-list-item-title>บัตรเครดิต</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="makePayment(props.item,'transfer')">
                        <v-list-item-title>โอนเงิน</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn class="mr-1" v-permission="'cashier-invoice-rebuild'" @click="rebuildInvoice(props.item.invoiceId)">Rebuild</v-btn>
                  <v-btn class="mr-1" v-permission="'cashier-invoice-void'" :disabled="(props.item.payments && props.item.payments.filter(o => o.isVoid == false).length>0) || !!props.item.isVoid" @click="makeVoidInvoice(props.item)">ยกเลิก</v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mr-1" v-on="on">พิมพ์</v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_summary_insurance')">
                        <v-list-item-title>แบบสรุป (ประกัน)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_summary_cgd')">
                        <v-list-item-title>แบบสรุป (กรมบัญชีกลาง)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_detail_insurance')">
                        <v-list-item-title>แบบรายละเอียด (ประกัน)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_detail_cgd')">
                        <v-list-item-title>แบบรายละเอียด (กรมบัญชีกลาง)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_detail_cgd_excess')">
                        <v-list-item-title>แบบแสดงส่วนเกิน (กรมบัญชีกลาง)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_en')">
                        <v-list-item-title>English (Summary)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'invoice_en_detail')">
                        <v-list-item-title>English (Detail)</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:expanded-item="{ headers,item }">
                  <td :colspan="headers.length" class="pa-0">
                    <v-enhance-table
                      :items="item.payments"
                      :headers="paymentHeader"
                    >
                      <template v-slot:item.isVoid="props">
                        <v-icon color="red" v-if="!!props.item.isVoid">mdi-file-remove-outline</v-icon>
                        <v-icon v-else>mdi-text-box-check-outline</v-icon>
                      </template>
                      <template v-slot:item.paymentAction="props">
                        <v-btn class="mr-1" v-permission="'cashier-payment-void'" :disabled="!!props.item.isVoid" @click="makeVoidPayment(props.item)">ยกเลิก</v-btn>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn class="mr-1" v-on="on">พิมพ์</v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_summary_insurance')">
                              <v-list-item-title>แบบสรุป (ประกัน)</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_summary_cgd')">
                              <v-list-item-title>แบบสรุป (กรมบัญชีกลาง)</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_detail_insurance')">
                              <v-list-item-title>แบบรายละเอียด (ประกัน)</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_detail_cgd')">
                              <v-list-item-title>แบบรายละเอียด (กรมบัญชีกลาง)</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_detail_cgd_excess')">
                              <v-list-item-title>แบบแสดงส่วนเกิน (กรมบัญชีกลาง)</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_en')">
                              <v-list-item-title>English (Summary)</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printDocument(props.item.documentId,'receipt_en_detail')">
                              <v-list-item-title>English (Detail)</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-enhance-table>
                  </td>
                </template>
              </v-model-table>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
      <v-col cols="12" v-if="!currentPeriod && $store.getters.getConfig('location') && $store.getters.getConfig('location').cashierId">
        <v-model-table model-name="CashierPeriods" model-title="ประวัติการเปิดกะ"
          :model-api="['models','Accounting','CashiersPeriods']"
          :model-api-search="{cashierId: $store.getters.getConfig('location').cashierId}"
          api-order-by="id,desc"
          :headers="periodHeaders"
          server-pagination
          :insertable="false"
        >
          <template v-slot:item.action="props">
            <v-btn color="primary" @click="printPeriod(props.item.id)">Print</v-btn>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
    <v-action-dialog form-title="ชำระเงิน" save-caption="ชำระเงิน" cancel-caption="ยกเลิก" 
      hide-activate-button max-width="45vw"
      :object="currentPaymentObject"
      v-model="paymentDialog"
      @saveData="onPayment"
    >
      <template v-slot="{data,rules}">
        <v-container>
          <v-row v-if="data.paymentMethod=='cash'">
            <v-col cols="12" md="4" align-self="center">
              <v-subheader class="headline">ยอดค้างชำระ</v-subheader>
            </v-col>
            <v-col cols="12" md="8" align-self="center">
              <v-text-field :value="currentInvoice.amountOutstanding" :read-only="true" suffix="บาท"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" align-self="center">
              <v-subheader class="headline">รับเงิน</v-subheader>
            </v-col>
            <v-col cols="12" md="8" align-self="center">
              <v-text-field :rules="[rules.require(),rules.numeric()]" v-model="data.amountReceive" ref="amountPaid" suffix="บาท"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" align-self="center">
              <v-subheader class="headline">เงินทอน</v-subheader>
            </v-col>
            <v-col cols="12" md="8" align-self="center">
              <v-text-field :value="(data.amountReceive-currentInvoice.amountOutstanding>0) ? data.amountReceive-currentInvoice.amountOutstanding : 0" :read-only="true" suffix="บาท"></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="data.paymentMethod=='creditcard'">
            <v-col cols="12" md="4" align-self="center">
              <v-subheader class="headline">ยอดค้างชำระ</v-subheader>
            </v-col>
            <v-col cols="12" md="8" align-self="center">
              <v-text-field :value="currentInvoice.amountOutstanding" :read-only="true" suffix="บาท"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" align-self="center">
              <v-subheader class="headline">ยอดชำระ</v-subheader>
            </v-col>
            <v-col cols="12" md="8" align-self="center">
              <v-text-field :rules="[rules.require(),rules.numeric()]" v-model="data.amountPaid" ref="amountPaid" suffix="บาท"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" align-self="center">
              <v-text-field :rules="[rules.require()]" v-model="data.creditCardNo" label="หมายเลขบัตร (4 หลักท้าย)"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" align-self="center">
              <v-text-field :rules="[rules.require()]" v-model="data.creditCardApprovalCode" label="Approval Code"></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="data.paymentMethod=='transfer'">
            <v-col cols="12" md="4" align-self="center">
              <v-subheader class="headline">ยอดค้างชำระ</v-subheader>
            </v-col>
            <v-col cols="12" md="8" align-self="center">
              <v-text-field :value="currentInvoice.amountOutstanding" :read-only="true" suffix="บาท"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" align-self="center">
              <v-subheader class="headline">ยอดชำระ</v-subheader>
            </v-col>
            <v-col cols="12" md="8" align-self="center">
              <v-text-field :rules="[rules.require(),rules.numeric()]" v-model="data.amountPaid" ref="amountPaid" suffix="บาท"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" align-self="center">
              <v-subheader class="headline">เลขที่อ้างอิงรายการ</v-subheader>
            </v-col>
            <v-col cols="12" md="8" align-self="center">
              <v-text-field :rules="[rules.require()]" v-model="data.paymentDetail"></v-text-field>
              <v-hidden-field v-model="data.paymentAccount" item-value="982-0-27827-4"></v-hidden-field>
            </v-col>
          </v-row>

        </v-container>
      </template>
    </v-action-dialog>
    <v-action-dialog form-title="ยกเลิกการชำระเงิน" save-caption="ยืนยัน" cancel-caption="ยกเลิก" 
      hide-activate-button max-width="40vw"
      :object="currentReceiptObject"
      v-model="paymentVoidDialog"
      @saveData="onPaymentVoid"
    >
      <template v-slot="{data,rules}">
        <v-container>
          <v-row>
            <v-col cols="12">
              <label class="headline">เลขที่ใบเสร็จ {{ data.receiptId }}</label>
            </v-col>
            <v-col x12>
              <v-text-field v-model="data.note" :rules="[rules.require()]" label="เหตุผลการยกเลิก"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-action-dialog>
    <v-action-dialog form-title="ยกเลิกใบแจ้งหนี้" save-caption="ยืนยัน" cancel-caption="ยกเลิก" 
      hide-activate-button max-width="40vw"
      :object="currentInvoiceObject"
      v-model="invoiceVoidDialog"
      @saveData="onInvoiceVoid"
    >
      <template v-slot="{data,rules}">
        <v-container>
          <v-row>
            <v-col cols="12">
              <label class="headline">เลขที่ใบแจ้งหนี้ {{ data.invoiceId }}</label>
            </v-col>
            <v-col x12>
              <v-text-field v-model="data.note" :rules="[rules.require()]" label="เหตุผลการยกเลิก"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-action-dialog>
  </v-container>
</template>

<script>
import api from 'tantee-common/lib/api'
import { sumBy,filter,isUndefined,isEmpty } from 'lodash'
import helperDateTime from 'tantee-common/lib/helperDateTime'
import printDocument from '@/components/mixins/printDocument'

export default {
  mixins: [printDocument],
  data: ()=>({
    currentEncounterType: 'AMB',
    currentHistoryHn: undefined,
    currentHistoryFromDate: undefined,
    currentHistoryToDate: undefined,
    currentPeriod: undefined,
    currentInvoice: undefined,
    currentPaymentObject: undefined,
    paymentDialog: false,
    currentReceiptObject: undefined,
    paymentVoidDialog: false,
    currentInvoiceObject: undefined,
    invoiceVoidDialog: false,
    headersTransactions: [
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'name_th',
        class: 'body-2'
      },
      {
        text: 'Name',
        value: 'name_en',
        class: 'body-2'
      },
      {
        text: 'ยอดรวม',
        value: 'amount',
        align: 'right',
        class: 'body-2 font-weight-bold green--text text--darken-3',
        sortable: false,
      },
      {
        text: 'เรียกเก็บผู้ป่วย',
        value: 'amountChargetoPatient',
        align: 'right',
        class: 'body-2 font-weight-bold red--text text-darken-1',
        sortable: false,
      },
      {
        text: 'Action',
        value: 'cashierAction',
        align: 'center',
        sortable: false,
        class: 'body-2'
      }
    ],
    headersInvoices: [
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'name_th',
        class: 'body-2'
      },
      {
        text: 'Name',
        value: 'name_en',
        class: 'body-2'
      },
      {
        text: 'ยอดค้างชำระรวม',
        value: 'amountOutstanding',
        class: 'body-2 font-weight-bold red--text text-darken-1',
      },
    ],
    headersTodayInvoices: [
      {
        text: '',
        value: 'isVoid',
        width: '20px',
      },
      {
        text: 'Invoice Date',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: 'Invoice ID',
        value: 'invoiceId',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'สิทธิ์/ประกัน',
        value: 'insurance',
        class: 'body-2'
      },
      {
        text: 'จำนวนเงิน',
        value: 'amount',
        class: 'body-2'
      },
      {
        text: 'ค้างชำระ',
        value: 'amountOutstanding',
        class: 'body-2 font-weight-bold red--text text-darken-1',
      },
      {
        text: 'Action',
        value: 'invoiceAction',
        align: 'center',
        sortable: false,
        class: 'body-2'
      }
    ],
    transactionHeader: [
      {
        text: 'Date',
        value: 'transactionDateTime',
        class: 'body-2'
      },
      {
        text: 'EncounterType',
        value: 'encounter.encounterType',
        class: 'body-2'
      },
      {
        text: 'Type',
        value: 'product.productType',
        class: 'body-2'
      },
      {
        text: 'Product',
        value: 'productCode',
        class: 'body-2'
      },
      {
        text: 'Insurance',
        value: 'insurance',
        class: 'body-2'
      },
      {
        text: 'Quantity',
        value: 'quantity',
        class: 'body-2',
      },
      {
        text: 'Total Price',
        value: 'totalPrice',
        class: 'body-2'
      },
      {
        text: 'Discount',
        value: 'discount',
        class: 'body-2'
      },
      {
        text: 'Final Price',
        value: 'finalPrice',
        class: 'body-2'
      },
    ],
    invoiceHeader: [
      {
        text: 'Invoice Date',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: 'Invoice ID',
        value: 'invoiceId',
        class: 'body-2'
      },
      {
        text: 'จำนวนเงิน',
        value: 'amountDue',
        class: 'body-2'
      },
      {
        text: 'ชำระแล้ว',
        value: 'amountPaid',
        class: 'body-2'
      },
      {
        text: 'ค้างชำระ',
        value: 'amountOutstanding',
        class: 'body-2 font-weight-bold red--text text-darken-1',
      },
      {
        text: 'Action',
        value: 'invoiceAction',
        align: 'center',
        sortable: false,
        class: 'body-2'
      }
    ],
    paymentHeader: [
      {
        text: '',
        value: 'isVoid',
        width: '20px',
      },
      {
        text: 'Receipt Date',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: 'Receipt ID',
        value: 'receiptId',
        class: 'body-2'
      },
      {
        text: 'Cashier ID',
        value: 'cashierId',
        class: 'body-2'
      },
      {
        text: 'Method',
        value: 'paymentMethod',
        class: 'body-2'
      },
      {
        text: 'Detail',
        value: 'paymentDetail',
        class: 'body-2'
      },
      {
        text: 'จำนวนเงินค้างชำระ',
        value: 'amountDue',
        class: 'body-2'
      },
      {
        text: 'ชำระครั้งนี้',
        value: 'amountPaid',
        class: 'body-2 font-weight-bold green--text text--darken-3'
      },
      {
        text: 'ค้างชำระ',
        value: 'amountOutstanding',
        class: 'body-2 font-weight-bold red--text text-darken-1',
      },
      {
        text: 'Action',
        value: 'paymentAction',
        align: 'center',
        sortable: false,
        class: 'body-2'
      }
    ],
    periodHeaders: [
      {
        text: 'Cashier ID',
        value: 'cashierId',
        class: 'body-2'
      },
      {
        text: 'เวลาเริ่มกะ',
        value: 'startDateTime',
        class: 'body-2'
      },
      {
        text: 'เวลาสิ้นสุดกะ',
        value: 'endDateTime',
        class: 'body-2',
      },
      {
        text: 'เงินสดเริ่มต้น',
        value: 'initialCash',
        class: 'body-2'
      },
      {
        text: 'เงินสดนับปิดกะ',
        value: 'finalCash',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '200px',
        sortable: false,
        class: 'body-2'
      }
    ],
    invoiceQuery: {
      "whereHas#UnpaidInvoices": null,
    },
    todayInvoiceQuery: {
      "whereDate#created_at": helperDateTime.today().format('YYYY-MM-DD'),
    },
  }),
  methods: {
    sumBy(items,iteratee,condition=undefined) {
      if (!isUndefined(condition)) items = filter(items,condition)
      return sumBy(items,iteratee)
    },
    sumAmount(items,iteratee) {
      return this.sumBy(items,iteratee)
    },
    sumAmountToCharge(items) {
      return this.sumBy(items,'finalPrice',(o)=>{return !o.sold_patients_insurances || o.sold_patients_insurances.isChargeToPatient}) + this.sumBy(items,'finalExcessPrice',(o)=>{return o.sold_patients_insurances && !o.sold_patients_insurances.isChargeToPatient})
    },
    makeStatement(hn,transactions,callback) {
      let postData = {
        hn: hn,
        transactions: transactions,
      }
      let invoicePromise = api.post(['Encounter','TransactionController','createStatement'],postData).then((returnData)=>{
        if (returnData.success) {
          this.printDocument(returnData.data)
        }
        if (!isUndefined(callback)) callback()
      })
      .catch((e)=>void e)
      this.$loading('Create statement(s)',invoicePromise)
    },
    makeInvoice(hn,transactions,callback) {
      let postData = {
        hn: hn,
        transactions: transactions,
        cashiersPeriodsId: this.currentPeriod.id,
      }
      let invoicePromise = api.post(['Encounter','TransactionController','createInvoice'],postData).then((returnData)=>{
        if (returnData.success) {
          this.printDocument(returnData.data)
        }
        if (!isUndefined(callback)) callback()
      })
      .catch((e)=>void e)
      this.$loading('Create invoice(s)',invoicePromise)
    },
    rebuildInvoice(invoiceId,callback) {
      let postData = {
        invoiceId: invoiceId,
      }
      let invoicePromise = api.post(['Encounter','TransactionController','rebuildInvoice'],postData).then((returnData)=>{
        if (returnData.success) {
          this.printDocument(returnData.data)
        }
        if (!isUndefined(callback)) callback()
      })
      .catch((e)=>void e)
      this.$loading('Rebuild invoice(s)',invoicePromise)
    },
    makePayment(invoice,paymentMethod) {
      if (this.currentPeriod) {
        this.currentPaymentObject = {
          cashiersPeriodsId: this.currentPeriod.id,
          invoiceId: invoice.invoiceId,
          paymentMethod: paymentMethod,
        }
        
        this.currentInvoice = invoice
      
        this.$nextTick(()=>{
          this.paymentDialog = true
          this.$nextTick(()=>{
            this.$nextTick(()=>{
              this.$nextTick(()=>{
                if (this.$refs.amountPaid) this.$refs.amountPaid.focus()
              })
            })
          })
        })
      }
    },
    makeVoidPayment(receipt) {
      this.currentReceiptObject = receipt
      this.paymentVoidDialog = true
    },
    makeVoidInvoice(invoice) {
      this.currentInvoiceObject = invoice
      this.invoiceVoidDialog = true
    },
    onPayment(paymentData,callback) {
      if (!isUndefined(paymentData.amountReceive)) {
        if (this.currentInvoice.amountOutstanding<paymentData.amountReceive) paymentData.amountPaid = this.currentInvoice.amountOutstanding
        else paymentData.amountPaid = paymentData.amountReceive
      }
      if (!isUndefined(paymentData.creditCardNo) && !isUndefined(paymentData.creditCardApprovalCode)) {
        paymentData.paymentDetail = 'x-'+paymentData.creditCardNo+' Approval Code: '+paymentData.creditCardApprovalCode
      }
      let paymentPromise = api.post(['Encounter','TransactionController','createPayment'],paymentData).then((returnData)=>{
        if (returnData.success) {
          this.printDocument(returnData.data)
          if (!isUndefined(callback)) callback.done()
          if (this.$refs.todayInvoiceTable) this.$refs.todayInvoiceTable.loadData()
          if (this.$refs.unpaidInvoiceTable) this.$refs.unpaidInvoiceTable.loadData()
          if (this.$refs.historyInvoiceTable) this.$refs.historyInvoiceTable.loadData()
          if (this.$refs.cashierBar) this.$refs.cashierBar.loadData()
        } else {
          if (!isUndefined(callback)) callback.error(returnData.errorTexts)
        }
      })
      .catch((error)=> {
        if (!isUndefined(callback)) callback.error(error.errorTexts)
      })
      this.$loading('Perform payment',paymentPromise)
    },
    onPaymentVoid(receiptData,callback) {
      let voidPromise = api.post(['Encounter','TransactionController','voidPayment'],Object.assign({},receiptData,{isVoidCashiersPeriodsId: this.currentPeriod.id})).then((returnData)=>{
        if (returnData.success) {
          this.printDocument(returnData.data)
          if (!isUndefined(callback)) callback.done()
          if (this.$refs.todayInvoiceTable) this.$refs.todayInvoiceTable.loadData()
          if (this.$refs.unpaidInvoiceTable) this.$refs.unpaidInvoiceTable.loadData()
          if (this.$refs.historyInvoiceTable) this.$refs.historyInvoiceTable.loadData()
        } else {
          if (!isUndefined(callback)) callback.error(returnData.errorTexts)
        }
      })
      .catch((error)=> {
        if (!isUndefined(callback)) callback.error(error.errorTexts)
      })
      this.$loading('Void payment',voidPromise)
    },
    onInvoiceVoid(invoiceData,callback) {
      let voidPromise = api.post(['Encounter','TransactionController','voidInvoice'],Object.assign({},invoiceData,{isVoidCashiersPeriodsId: this.currentPeriod.id})).then((returnData)=>{
        if (returnData.success) {
          this.printDocument(returnData.data)
          if (!isUndefined(callback)) callback.done()
          if (this.$refs.todayInvoiceTable) this.$refs.todayInvoiceTable.loadData()
          if (this.$refs.unpaidInvoiceTable) this.$refs.unpaidInvoiceTable.loadData()
          if (this.$refs.historyInvoiceTable) this.$refs.historyInvoiceTable.loadData()
        } else {
          if (!isUndefined(callback)) callback.error(returnData.errorTexts)
        }
      })
      .catch((error)=> {
        if (!isUndefined(callback)) callback.error(error.errorTexts)
      })
      this.$loading('Void invoice',voidPromise)
    },
    onPeriodLoaded(currentPeriod) {
      this.currentPeriod = currentPeriod
    },
    onPeriodUnloaded(currentPeriod) {
      this.currentPeriod = undefined
    },
    printPeriod(id) {
      let periodData = {
        id: id
      }
      api.post(['Accounting','CashierController','getCashierPeriodSummary'],periodData).then((returnData)=>{
        if (returnData.success) {
          this.printTemporary(undefined,undefined,'cashier_period_summary',returnData.data)
        }
      })
      .catch((error)=> {
        void error
      })
    }
  },
  computed: {
    transactionQuery() {
      let returnQuery = {
        "whereHas#UninvoicedTransactions": null,
      }

      if (this.currentEncounterType=='IMP') {
        returnQuery['whereHas#ActiveEncountersIpd'] = null
      } else {
        returnQuery['whereDoesntHave#ActiveEncountersIpd'] = null
      }

      return returnQuery
    },
    historyInvoiceQuery() {
      let returnQuery = {}
      if (this.currentHistoryHn) returnQuery['hn'] = this.currentHistoryHn
      if (this.currentHistoryFromDate) returnQuery['whereDate#created_at#>='] = this.currentHistoryFromDate
      if (this.currentHistoryToDate) returnQuery['whereDate#created_at#<='] = this.currentHistoryToDate
      
      return isEmpty(returnQuery) ? undefined : returnQuery
    }
  },
}
</script>

<style lang="css" scoped>
</style>
